<template>
  <div v-if="show" class="platter light p-3">
    <h4 class="accent"><template v-if="category">Sub</template>Category</h4>
    <ul class="list-unstyled mb-0">
      <li>
        <a
          href="#"
          :class="{ active: !current }"
          @click.prevent="$emit('change', undefined)"
        >
          All <small>({{ vendorsCount }})</small>
        </a>
      </li>
      <li v-for="m in categories" :key="m.name">
        <a
          href="#"
          :class="{
            active:
              current &&
              current.toLowerCase().replaceAll(' ', '-') ===
                m.name.toLowerCase().replaceAll(' ', '-')
          }"
          @click.prevent="
            $emit('change', m.name.toLowerCase().replaceAll(' ', '-'))
          "
        >
          {{ m.displayName }}
          <small>({{ m.count }})</small>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Enumerable from "linq";

export default {
  name: "VendorsCatFilter",
  computed: {
    show() {
      const s = this.categories.length > 1;
      if (s) this.$emit("showing");
      return s;
    },
    categories() {
      const cats = Enumerable.from(this.$store.state.categories);
      return Enumerable.from(this.vendors)
        .selectMany(v => v.categories)
        .groupBy(v => v)
        .select(v => {
          return {
            name: v.key(),
            count: v.count(),
            displayName: cats.firstOrDefault(
              i =>
                i.key.toLowerCase() === v.key().toLowerCase() ||
                i.name.toLowerCase() === v.key().toLowerCase()
            )?.name
          };
        })
        .orderBy(v => v.displayName)
        .where(v => v.displayName && v.name !== this.category?.name)
        .toArray();
    },
    vendorsCount() {
      return this.vendors.length;
    }
  },
  props: {
    vendors: { type: Array },
    current: { type: String },
    category: { type: Object }
  }
};
</script>
